import { getPointMessageType } from "src/data/devices/logic/deviceLogic"
import { useFetchDevicePointMessage } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { IParadiseDevicePointMessage } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { MDialog } from "src/ui/Dialog/MDialog"
import { JsonTree } from "src/ui/JsonTree/JsonTree"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { formatDate } from "src/utils/l10n"

export function ParadiseDevicePointMessageDialog({
  open,
  onClose,
  deviceId,
  message,
}: {
  open: boolean
  onClose: () => void
  deviceId: string
  message: IParadiseDevicePointMessage
}) {
  const fetchPointMessage = useFetchDevicePointMessage({
    deviceId,
    messageType: message.message_type,
    timestamp: message.timestamp_ms,
    options: {
      enabled: open,
    },
  })

  function getRandomWidth() {
    const max = 100
    const min = 50

    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  return (
    <MDialog
      open={open}
      maxWidth="md"
      showCancel={false}
      onClose={onClose}
      onConfirm={onClose}
      confirmLabel="Close"
      title={getPointMessageType(message.message_type)}
      description={formatDate({
        date: new Date(message.timestamp_ms).toISOString(),
        clockType: "24",
        timezone: "utc",
      })}
    >
      {fetchPointMessage.isError && (
        <MBanner type="error" fullWidth>
          {fetchPointMessage.error.message}
        </MBanner>
      )}

      {fetchPointMessage.isLoading && (
        <>
          {new Array(20).fill(null).map((_, i) => (
            <MSkeleton height={20} width={getRandomWidth() + "%"} key={i} />
          ))}
        </>
      )}

      {fetchPointMessage.isSuccess && (
        <JsonTree json={JSON.parse(fetchPointMessage.data)} />
      )}
    </MDialog>
  )
}
