import { useMemo, useState } from "react"

import { ParadiseDeviceFirmwareConfirmation } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceDetails/ParadiseDeviceOverview/ParadiseDeviceFirmware/ParadiseDeviceFirmwareConfirmation"
import { useFetchParadiseFirmwares } from "src/data/paradise/paradiseFirmwares/queries/paradiseFirmwareQueries"
import { IParadiseFirmware } from "src/data/paradise/paradiseFirmwares/types/paradiseFirmwareQueryTypes"
import { Combobox } from "src/ui/Combobox/Combobox"
import { MText } from "src/ui/MText"
import { Maybe } from "src/utils/tsUtil"

export function ParadiseDeviceSelectFirmware({
  initialFirmware,
  hardwareVersion,
  onSelect,
}: {
  initialFirmware: Maybe<IParadiseFirmware>
  hardwareVersion: number
  onSelect: (selected: IParadiseFirmware) => void
}) {
  const [selectedFirmware, setSelectedFirmware] = useState(initialFirmware)
  const [searchedFirmware, setSearchedFirmware] = useState("")

  const fetchFirmwares = useFetchParadiseFirmwares({
    filter: {
      hardware_version: hardwareVersion,
    },
  })

  const options = useMemo(() => {
    if (fetchFirmwares.data) {
      return fetchFirmwares.data.firmwares.flatMap((f) => {
        if (
          f.description.toLowerCase().includes(searchedFirmware.toLowerCase())
        ) {
          return {
            ...f,
          }
        }

        return []
      })
    }

    return []
  }, [searchedFirmware, fetchFirmwares.data])

  return (
    <>
      <div>
        <MText variant="body">Select wanted firmware</MText>
        <Combobox
          selectedValue={selectedFirmware?.name.toString() ?? ""}
          options={options.map((o) => ({
            value: o.name.toString(),
            label: o.name.toString(),
            description: o.description,
          }))}
          initialInput={selectedFirmware?.name.toString()}
          onSearch={setSearchedFirmware}
          onChange={(value) => {
            const selectedFirmware = options.find(
              (o) => o.name.toString() === value
            )

            if (selectedFirmware) {
              setSelectedFirmware(selectedFirmware)
              onSelect(selectedFirmware)
            }
          }}
        ></Combobox>
      </div>
      {selectedFirmware && selectedFirmware.description && (
        <ParadiseDeviceFirmwareConfirmation
          title="Wanted firmware"
          description={selectedFirmware.description}
          fwNumber={selectedFirmware.name}
          tags={selectedFirmware.tags}
        />
      )}
    </>
  )
}
